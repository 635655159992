import React from "react";
import PT from "prop-types";
import { MDXProvider } from "@mdx-js/react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Layout, Headline, Container, Meta } from "~/src/components";
import { down, header } from "~/src/config/vars";

const IndexPage = ({ data }) => {
    return (
        <Layout>
            <Meta
                title={"Danger Cactus"}
                description={
                    "Thoughtful graphics, a hands-on creative, passion for the details, organized interface and adaptability, fast-to-open platform – must keys to the success."
                }
            />
            <Container>
                <SPageProjects>
                    <Headline>Work</Headline>
                    <SDescription>
                        Thoughtful graphics, user-friendliness, competent
                        content, clean interface and adaptability - such, in our
                        opinion, should be a modern communication Internet
                        platform.
                    </SDescription>
                    <MDXProvider>
                        <SBlock>
                            {data.allMdx.nodes.map((node) => (
                                <MDXRenderer key={node.id}>
                                    {node.body}
                                </MDXRenderer>
                            ))}
                        </SBlock>
                    </MDXProvider>
                </SPageProjects>
            </Container>
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PT.object.isRequired,
};

const SPageProjects = styled.div`
    padding-top: ${header.height};
`;
const SDescription = styled.p`
    margin-bottom: 3em;
    max-width: 800px;
    ${down("sm")} {
        margin-bottom: 2em;
    }
`;
const SBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const query = graphql`
    query ProjectsPage {
        allMdx(
            sort: { fields: frontmatter___created_at, order: DESC }
            filter: { fields: { section: { eq: "project_cards" } } }
        ) {
            nodes {
                id
                body
                fields {
                    section
                }
                frontmatter {
                    slug
                }
            }
        }
    }
`;

export default IndexPage;
